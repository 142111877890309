import React, { memo } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const FallbackView = ({ Icon, text, title, btnText, onClickHandler }) => {
  return (
    <div className="fallback-view" data-cy="fallback-view">
      <div className="fallback-view__icon">
        <Icon />
      </div>
      <div className="fallback-view__title">{title}</div>
      <div className="fallback-view__text">{text}</div>
      <button
        type="button"
        className="fallback-view__btn"
        onClick={onClickHandler}
      >
        {btnText}
      </button>
    </div>
  );
};

FallbackView.propTypes = {
  title: PropTypes.string,
  Icon: PropTypes.func,
  text: PropTypes.string,
  onClickHandler: PropTypes.func,
  btnText: PropTypes.string
};

FallbackView.defaultProps = {
  onClickHandler: null
};

export default memo(FallbackView);
